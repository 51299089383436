@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-Regular.woff2') format('woff2'), url('fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-SemiBold.woff2') format('woff2'), url('fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button,
input,
textarea {
  appearance: none;
  -webkit-appearance: none;
  font-family: inherit;
}

button:focus {
  box-shadow: none !important;
}

*:focus {
  outline: none;
}

body {
  font-family: 'Poppins';
  font-weight: 400;
  color: #050620;
}

a {
  display: inline-block;
  font: inherit;
  color: inherit;
  transition: 300ms;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

a img {
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 1180px;
  margin: 0 auto;
}

@media screen and (max-width: 1240px) {
  .container {
    width: 970px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    width: 750px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

.wrapper {
  overflow: hidden;
  position: relative;
  background: #fcfcfc;
}

.header {
  padding: 16px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 100;

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 138px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__item {
    @media only screen and (max-width: 991px) {
      position: fixed;
      right: -240px;
      top: 0;
      width: 240px;
      background: #fff;
      z-index: 150;
      transition: 300ms;

      &.active {
        right: 0;
      }
    }

    &-inner {
      @media only screen and (max-width: 991px) {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 80px 20px 80px 20px;
        min-height: 100vh;
      }
    }
  }

  &__strong {
    display: none;

    @media only screen and (max-width: 991px) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 30px;
      right: 20px;
      width: 30px;
      height: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    li {
      margin-right: 40px;

      @media only screen and (max-width: 991px) {
        margin-right: 0;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 14px;

        @media only screen and (max-width: 991px) {
          font-size: 18px;
        }

        &:hover {
          color: #bd00ff;
        }
      }
    }
  }

  &__user {
    position: relative;
    z-index: 5;

    &-header {
      display: flex;
      align-items: center;
      background: #ffffff;
      cursor: pointer;
      padding: 8px 20px 8px 8px;
      box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.04);
      border-radius: 24px;
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      min-width: 32px;
      margin-right: 12px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &-block {
      display: flex;
      align-items: center;
    }

    &-name {
      font-size: 14px;
    }

    &-arrow {
      display: flex;
      min-width: 12px;
      width: 12px;
      margin-left: 12px;
      align-items: center;
      justify-content: center;
      transition: 300ms;
    }

    &-drop {
      background: #ffffff;
      box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.12);
      border-radius: 24px;
      position: absolute;
      display: none;
      top: 52px;
      left: 0;
      width: 100%;

      @media only screen and (max-width: 991px) {
        position: static;
        margin-top: 10px;
      }

      li {
        a {
          display: flex;
          align-items: center;
          padding: 12px 20px;
          font-size: 14px;

          &:hover {
            color: #bd00ff;
          }

          img {
            margin-right: 12px;
          }
        }
      }
    }
  }

  &__burger {
    display: none;
    width: 30px;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    a {
      display: block;
      cursor: pointer;
      padding: 12px 0;
      span {
        position: relative;
        display: block;
        width: 30px;
        height: 5px;
        border-radius: 7px;
        background: #222;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        &:before,
        &:after {
          content: '';
          width: 30px;
          height: 5px;
          position: absolute;
          left: 0;
          background: #222;
          border-radius: 7px;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
        &:before {
          top: -8px;
        }
        &:after {
          top: 8px;
        }
      }
    }
  }
}

.settings {
  padding: 228px 0 138px 0;

  @media only screen and (max-width: 1240px) {
    padding: 190px 0 100px 0;
  }

  @media only screen and (max-width: 991px) {
    padding: 160px 0 80px 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 100px 0 60px 0;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 76px;
    right: 0;

    @media only screen and (max-width: 1240px) {
      width: 350px;
    }

    @media only screen and (max-width: 991px) {
      width: 300px;
    }

    @media only screen and (max-width: 767px) {
      width: 200px;
    }
  }

  &__title {
    font-size: 64px;
    margin-bottom: 68px;

    @media only screen and (max-width: 767px) {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }

  &__content {
    max-width: 679px;
    width: 100%;
    position: relative;
    z-index: 5;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  &__user {
    background: #ffffff;
    border-radius: 24px;
    padding: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 68px;
      min-width: 68px;
      margin-right: 24px;
      border-radius: 50%;
      overflow: hidden;

      @media only screen and (max-width: 767px) {
        min-width: 45px;
        width: 45px;
        height: 45px;
        margin-right: 15px;
      }

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    &-header {
      display: flex;
      align-items: center;
    }

    &-name {
      font-size: 32px;
      margin-right: 8px;

      @media only screen and (max-width: 767px) {
        font-size: 24px;
        margin-right: 6px;
      }
    }

    &-redaction {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        width: 20px;
      }
    }

    &-file {
      display: flex;
      align-items: center;
      width: 100%;
      height: 20px;
      position: relative;
      cursor: pointer;

      input {
        appearance: none;
        font-size: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        cursor: pointer;
        left: 0;
      }

      span {
        font-size: 16px;
        color: #bd00ff;
      }
    }
  }

  &__item {
    background: #ffffff;
    border-radius: 24px;
    margin-bottom: 16px;
    padding: 24px;
    box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.04);

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }

    input {
      width: 100%;
      padding: 0 24px;
      height: 56px;
      border: 1px solid rgba(5, 6, 32, 0.24);
      border-radius: 32px;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
      color: inherit;

      &:last-child {
        margin-bottom: 0;
      }

      &::placeholder {
        color: rgba(5, 6, 32, 0.64);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-size: 32px;
    margin-bottom: 24px;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 56px;
    background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    border-radius: 32px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    img {
      margin-right: 8px;
    }
  }
}

.sign {
  min-height: 100vh;
  display: flex;
  background: #fcfcfc;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;

  &__content {
    max-width: 648px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.04);
    border-radius: 24px;

    &-inner {
      position: relative;
      padding: 48px;

      @media only screen and (max-width: 767px) {
        padding: 40px 20px;
      }
    }
  }

  &__strong {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 40px;
  }

  &__social {
    display: flex;
    margin-bottom: 40px;

    @media only screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 10px;
    }

    li {
      margin-right: 10px;
      width: 100%;

      @media only screen and (max-width: 767px) {
        margin: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 56px;
        border: 1px solid #050620;
        border-radius: 32px;

        &:hover {
          background: rgba(5, 6, 32, 0.08);
        }

        &:active {
          background: rgba(5, 6, 32, 0.12);
        }
      }
    }
  }

  &__form {
    input {
      width: 100%;
      padding: 0 24px;
      height: 56px;
      border: 1px solid rgba(5, 6, 32, 0.24);
      border-radius: 32px;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
      color: inherit;

      &:last-child {
        margin-bottom: 0;
      }

      &::placeholder {
        color: rgba(5, 6, 32, 0.64);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__inputs {
    margin-bottom: 120px;

    @media only screen and (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  &__login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    border-radius: 32px;
    color: #fff;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    transition: 300ms;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }
  }

  &__register {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border: 1px solid #050620;
    border-radius: 32px;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background: rgba(5, 6, 32, 0.08);
    }

    &:active {
      background: rgba(5, 6, 32, 0.12);
    }
  }
}

.sign-register {
  .sign__inputs {
    margin-bottom: 52px;
  }
}

.check {
  max-width: 580px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.04);
  border-radius: 24px;

  &__inner {
    position: relative;
    padding: 136px 0 115px 0;
    text-align: center;

    @media only screen and (max-width: 767px) {
      padding: 60px 20px;
    }
  }

  &__strong {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    cursor: pointer;
    right: 20px;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    margin: 0 auto 54px auto;

    @media only screen and (max-width: 767px) {
      width: 150px;
      height: 150px;
      min-width: 150px;
      margin-bottom: 30px;
    }
  }

  &__text {
    font-size: 32px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
}

.highcharts-legend-item {
  text {
    font-weight: 400;
    letter-spacing: 0.04em;
    font-size: 12px;
  }

  rect {
    display: block !important;
    transform: scale(0.8) translateY(3px);
  }
}

.percent {
  width: 45px;
  padding: 16px 0 !important;
}

.value {
  width: 50px;
  padding: 16px 0 !important;
}

.tradepercent {
  display: none;
}

#highcharts-h0w0gk3-0 {
  position: static !important;
}

.trade-table {
  display: none;
}

#lineChart {
  width: 100%;
  height: 300px;
  overflow: visible !important;

  .highcharts-container {
    overflow: visible;

    svg {
      overflow: visible;
    }
  }

  .highcharts-legend.highcharts-no-tooltip {
    transform: translate(342px, 309px);

    @media only screen and (max-width: 1240px) {
      transform: translate(234px, 308px);
    }

    @media only screen and (max-width: 991px) {
      transform: translate(490px, 314px);
    }

    @media only screen and (max-width: 767px) {
      transform: translate(9px, 342px);
    }
  }

  .highcharts-axis-line {
    opacity: 0;
  }
}

.percent {
  position: relative;

  span {
    display: flex !important;
    opacity: 1 !important;
    align-items: center;

    &.active {
      color: #23b480;

      &::before {
        transform: rotate(180deg);
        border-color: #23b480 transparent transparent transparent;
      }
    }

    &::before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      margin-right: 6px;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: #050620 transparent transparent transparent;
    }
  }
}

.tokens {
  position: relative;
  z-index: 5;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media only screen and (max-width: 767px) {
      padding-right: 16px;
    }
  }

  &__stop {
    width: 271px;
    height: 48px;
    border: 1px solid #050620;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: 300ms;
    margin-right: 0;
    margin-left: auto;

    &:hover {
      background: rgba(5, 6, 32, 0.08);
    }

    &:active {
      background: rgba(5, 6, 32, 0.12);
    }
  }

  &__title {
    font-size: 40px;

    @media only screen and (max-width: 767px) {
      font-size: 30px;
    }
  }

  .container {
    @media only screen and (max-width: 767px) {
      padding: 0;
      padding-left: 16px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 198px;
    height: 56px;
    background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    border-radius: 32px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      width: 150px;
      height: 45px;
      font-size: 12px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    img {
      margin-right: 8px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(5, 6, 32, 0.12);
    margin-bottom: 20px;

    @media only screen and (max-width: 767px) {
      padding-right: 16px;
    }
  }

  &__tab {
    cursor: pointer;
    transition: 300ms;
    margin-right: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #050620;
    opacity: 0.4;
    position: relative;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      opacity: 1;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #050620;
        position: absolute;
        bottom: -16px;
        left: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__table {
    &-btns {
      display: flex;
      align-items: center;
      max-width: 200px;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 14px;
      font-weight: 600;

      &:first-child {
        width: 99px;
        border-radius: 32px;
        color: #bd00ff;
        margin-right: 12px;

        &:hover {
          background: rgba(189, 0, 255, 0.12);
        }

        &:active {
          background: rgba(189, 0, 255, 0.24);
        }
      }

      &:last-child {
        width: 90px;
        background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
        border-radius: 32px;
        color: #fff;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
            linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
        }

        &:active {
          background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
            linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
        }
      }
    }

    &-first {
      border-collapse: collapse;
      background: #ffffff;
      box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.04);
      border-radius: 24px;
      width: 100%;
      overflow: hidden;

      tbody {
        width: 100%;
      }

      td {
        padding: 12px;
        font-size: 14px;
        font-weight: 600;
      }

      tr {
        &:first-child {
          td {
            font-size: 12px;
            letter-spacing: 0.04em;

            img {
              margin-left: 4px;
            }
          }
        }

        td {
          &:last-child {
            width: 271px;
          }
        }

        &:nth-child(2n) {
          background: rgba(5, 6, 32, 0.02);
        }
      }
    }

    &-second {
      border-collapse: collapse;
      background: #ffffff;
      box-shadow: 0px 24px 20px rgba(5, 6, 32, 0.04);
      border-radius: 24px;
      width: 100%;
      overflow: hidden;

      tbody {
        width: 100%;
      }

      td {
        padding: 12px;
        font-size: 14px;
        font-weight: 600;
      }

      tr {
        &:first-child {
          td {
            opacity: 0.4;
            font-size: 12px;
            letter-spacing: 0.04em;

            img {
              margin-left: 4px;
            }

            &:last-child {
              width: 200px;
            }
          }
        }

        &:nth-child(2n) {
          background: rgba(5, 6, 32, 0.02);
        }
      }
    }
  }

  &__content {
    @media only screen and (max-width: 767px) {
      overflow: auto;
    }
  }

  &__inner {
    display: none;

    @media only screen and (max-width: 767px) {
      min-width: 750px;
      width: 750px;
    }

    &:nth-child(2) {
      display: block;
    }
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-width: 32px;
    border-radius: 50%;
    overflow: hidden;
    height: 32px;
    margin-right: 12px;

    img {
      border-radius: 50%;
    }
  }

  &__type {
    font-size: 12px;
    margin-top: 2px;
    letter-spacing: 0.04em;
    opacity: 0.4;
  }

  &__percent {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    &-price {
      margin-right: 6px;
    }

    &-number {
      display: flex;
      align-items: center;
      color: #23b480;

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 5px 4px;
        border-color: transparent transparent #23b480 transparent;
        margin-right: 6px;
      }

      &.minus {
        color: #050620;

        &::before {
          transform: rotate(180deg);
          border-color: transparent transparent #050620 transparent;
        }
      }
    }
  }

  &__price {
    font-size: 12px;
    margin-top: 2px;
    letter-spacing: 0.04em;
    opacity: 0.4;
  }

  &__icons {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__percent-pair {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    img {
      margin-right: 10px;
      width: 20px;
      min-width: 20px;
    }
  }
}

.fancybox-button.fancybox-close-small {
  display: none;
}


.modal {
  &__inner {
    position: relative;
    padding: 32px;

    @media only screen and (max-width: 767px) {
      padding: 32px 10px;
    }
  }

  &__strong {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 24px;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  &__head {
    font-size: 12px;
    letter-spacing: 0.04em;
    margin-bottom: 8px;
    color: #050620;
    opacity: 0.4;
  }

  &__items {
    margin-bottom: 12px;
  }

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      width: 100%;
      height: 56px;
      border: 1px solid rgba(5, 6, 32, 0.24);
      border-radius: 32px;
      padding: 0 24px;
      font-size: 14px;
      font-weight: 600;
      color: #050620;

      &::placeholder {
        opacity: 0.64;
      }
    }
    
    .vodiapicker {
      -moz-appearance: none;
      -webkit-appearance: none;
      display: block;
      width: 100%;
      height: 56px;
      border: 1px solid rgba(5, 6, 32, 0.24);
      border-radius: 32px;
      padding: 0 24px;
      font-size: 14px;
      font-weight: 600;
      color: #050620;

      &::-ms-expand {
        display: none;
        }
    }

    .vodiapicker__block{
      position: relative;
      background: #fff;

      select{
        z-index: 1;
        position: relative;
        background: none;
      }

        .vodiapicker__block_pikk {
          top: 50%;
          right: 20px;
          position: absolute;
          transform: translateY(-50%);
        }
    }

    #a {
      padding-left: 0px;
    }

    #a img,
    .btn-select img {
      width: 24px;
    }

    #a li {
      list-style: none;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      padding: 10px 24px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    #a li:hover {
      background-color: #f4f3f3;
    }

    #a li img {
    }

    #a li span,
    .btn-select li span {
      margin-left: 8px;
    }

    /* item list */

    .b {
      display: none;
      width: 99%;
      max-width: 100%;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      border: 1px solid #050620;
      border-radius: 5px;
      padding-top: 40px;
      position: absolute;
      top: 20px;
      left: 0.5%;
      max-height: 220px;
      overflow-y: scroll;
      z-index: 50;
      background: #fff;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::-webkit-scrollbar {
        width: 2px;
        border-radius: 10;

        @media only screen and (max-width: 767px) {
          width: 1px;
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #050620;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #9f9f9f;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: transparent;
        border-radius: 10;
        background-color: transparent;
      }
    }

    .open {
      display: show !important;
    }

    .lang-select {
      position: relative;
    }

    .btn-select {
      width: 100%;
      max-width: 100%;
      position: relative;
      z-index: 55;
      height: 56px;
      background-color: #fff;
      border: 1px solid #050620;
      border-radius: 32px;
      padding: 0 24px;
      cursor: pointer;

      &.active {
        &::before {
          transform: rotate(180deg);
        }
      }

      &::before {
        content: '';
        display: block;
        transition: 300ms;
        width: 11px;
        height: 6px;
        background-image: 'assets/img/select-arrow.svg';
        background-repeat: no-repeat;
        background-size: 11px;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 24px;
      }

      li {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
    }
    .btn-select li {
      list-style: none;
      float: left;
      padding-bottom: 0px;
    }

    .btn-select:hover li {
      margin-left: 0px;
    }
  }

  &__youtube {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 120px;

    @media only screen and (max-width: 767px) {
      margin-bottom: 60px;
    }

    &:hover {
      text-decoration: none;
    }

    img {
      margin-right: 13px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border: 0;
    background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    border-radius: 32px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    &:disabled {
      background: rgba(255, 255, 255, 0.32);
      color: rgba(0, 0, 0, 0.336);
      border: 2px solid;
    }
    &:hover:enabled {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    &:active:enabled {
      background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }
  }

  &__video {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
    position: relative;

    &-play {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      cursor: pointer;
      z-index: 5;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border: 1px solid #050620;
    border-radius: 32px;
    font-size: 14px;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      height: 45px;
    }

    &:hover {
      background: rgba(5, 6, 32, 0.08);
    }

    &:active {
      background: rgba(5, 6, 32, 0.12);
    }
  }

  &__token {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;

    &.active {
      background: rgba(189, 0, 255, 0.12);

      .modal__token-image {
        img {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 14px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        &:last-child {
          display: none;
        }
      }
    }

    &-text {
      font-size: 16px;

      span {
        margin-left: 12px;
        opacity: 0.4;
      }
    }
  }

  &__tokens {
    margin-bottom: 20px;

    @media only screen and (max-width: 767px) {
      margin-bottom: 100px;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    border-radius: 32px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: 300ms;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      height: 45px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
        linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
    }

    img {
      margin-right: 8px;
    }

    span {
      margin: 0 4px;
    }
  }
}

.trade {
  background: rgba(5, 6, 32, 0.02);
  border: 1px solid rgba(5, 6, 32, 0.12);
  border-radius: 12px;
  padding: 12px;

  @media only screen and (max-width: 767px) {
    padding: 8px;
  }

  &__inner {
    overflow-y: scroll;
    padding-right: 12px;
    max-height: 330px;
    height: 100%;

    @media only screen and (max-width: 767px) {
      padding-right: 6px;
    }

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 10;
      background: rgba(5, 6, 32, 0.12);

      @media only screen and (max-width: 767px) {
        width: 2px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(5, 6, 32, 0.4);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #9f9f9f;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10;
      background-color: transparent;
    }
  }

  &__intro {

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    &-head {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 12px;

      img {
        border-radius: 50%;
      }
    }

    &-text {
      font-size: 14px;
      font-weight: 500;

      span {
        margin-left: 4px;
        opacity: 0.4;
      }
    }

    &-item {
      margin-right: 12px;

      @media only screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-right: 0;

        @media only screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        .trade__header-head {
          flex-direction: row-reverse;

          @media only screen and (max-width: 767px) {
            flex-direction: row;
          }
        }

        .trade__header-image {
          margin-right: 0;
          margin-left: 12px;

          @media only screen and (max-width: 767px) {
            margin-left: 0;
            margin-right: 12px;
          }
        }
      }
    }
  }

  &__blocks {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__block {
    margin-right: 12px;
    width: 100%;

    &-head {
      font-size: 12px;
      letter-spacing: 0.04em;
      color: #050620;
      margin-bottom: 8px;
      opacity: 0.4;
    }

    .t-dropdown-block {
      width: 100%;
      border: 0;
      box-sizing: border-box;
      position: relative;
      border: 1px solid #050620;
      border-radius: 32px;
      height: 56px;
    }

    .t-dropdown-list {
      display: none;
      z-index: 30;
      background: #fff;
      border: 1px solid #050620;
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      border-radius: 2px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      position: absolute;
      top: 60px;
      z-index: 40;
      width: 100% !important;

      ul {
        height: 100%;
        min-height: 70px;
        max-height: 140px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 2px;
          background-color: #ffffff;
          border-radius: 10;

          @media only screen and (max-width: 767px) {
            width: 1px;
          }
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba(5, 6, 32, 0.4);
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #9f9f9f;
          -webkit-transition: all 0.1s ease-in-out;
          transition: all 0.1s ease-in-out;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: transparent;
          border-radius: 10;
          background-color: transparent;
        }
      }
    }

    .t-dropdown-item {
      padding: 6px 10px;
      margin: 0;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }

    .t-dropdown-item:hover {
      background: rgb(201, 201, 201);
    }

    .t-dropdown-select {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      background: none;
      cursor: pointer;
      border-radius: 8px;
      box-sizing: content-box;
      font-size: 16px;
      color: #e5d6a0;
      transition: 400ms;
      &::before {
        position: absolute;
        display: block;
        content: '';
        width: 15px;
        height: 14px;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-image: 'assets/img/select-arrow.svg';
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 95% 50%;
        transition: 400ms;
      }

      &.active {
        &::before {
          transition: 400ms;
          top: 49%;
          right: 15px;
          transform: translateY(-50%) rotate(180deg);

          @media only screen and (max-width: 767px) {
            right: 3px;
          }
        }
      }
    }

    .t-dropdown-input {
      border: 0;
      background: none;
      height: 100%;
      position: relative;
      z-index: 50;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      padding: 0 18px 0 16px;
      color: #050620;

      &::placeholder {
        color: #050620;
      }
    }

    .t-select-btn:active {
      background-color: #f1f1f1;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__token {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      min-width: 32px;
      margin-right: 12px;
    }

    &-name {
      font-size: 14px;
      font-weight: 600;

      span {
        display: block;
        font-size: 12px;
        letter-spacing: 0.04em;
        color: #050620;
        opacity: 0.4;
        font-weight: 400;
      }
    }
  }

  &__amount {
    &-head {
      font-size: 16px;
      margin-bottom: -20px;
    }

    .irs-line {
      height: 2px;
      background: #bd00ff;
      opacity: 0.16;
      border-radius: 14px;
    }

    .irs-bar.irs-bar--single {
      height: 2px;
      background: #bd00ff;
      border-radius: 14px;
    }

    .irs-handle.single {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      cursor: pointer;
      background: #bd00ff;

      i {
        display: none;
      }
    }

    .irs-single {
      font-size: 12px;
      font-family: inherit;
      letter-spacing: 0.04em;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      filter: drop-shadow(0px 24px 20px rgba(5, 6, 32, 0.12));
      height: 30px;
      padding: 0;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid rgba(5, 6, 32, 0.04);
      border-radius: 12px;
      color: inherit;
      top: -20px;

      &::before {
        border: 0;
        width: 15px;
        height: 7px;
        margin-left: -7px;
        background: 'assets/img/arrow-range.svg';
        background-size: 15px;
        background-position: center;
      }
    }
  }

  &__side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #050620;
    opacity: 0.4;
  }

  &__free {
    margin-top: 24px;

    &-title {
      font-size: 24px;
      margin-bottom: 16px;
    }

    &-blocks {
      margin-bottom: 24px;
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-start {
      font-size: 16px;
      opacity: 0.4;
    }

    &-end {
      font-size: 16px;
      display: flex;
      font-weight: 600;
      align-items: center;

      &.plus {
        color: #23b480;

        &::before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 4px 5px 4px;
          border-color: transparent transparent #23b480 transparent;
          margin-right: 6px;
        }
      }
    }

    &-btns {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        display: block;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      height: 56px;

      @media only screen and (max-width: 767px) {
        width: 100%;
        height: 45px;
        margin-right: 0;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:first-child {
        border: 1px solid #050620;
        border-radius: 32px;

        &:hover {
          background: rgba(5, 6, 32, 0.08);
        }

        &:active {
          background: rgba(5, 6, 32, 0.12);
        }
      }

      &:last-child {
        color: #fff;
        background: linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
        border-radius: 32px;
        margin-right: 0;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)),
            linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
        }

        &:active {
          background: linear-gradient(0deg, rgba(5, 6, 32, 0.12), rgba(5, 6, 32, 0.12)),
            linear-gradient(93.98deg, #ff0047 3.25%, #bd00ff 58.07%, #00efff 116.43%);
        }
      }
    }
  }
}

.openMenuMobil{
  .header-mobil-menu-toggle{
    span:nth-child(1){
      transform: rotate(-45deg);
      margin-bottom: -9px;
      transition: 0.5s !important;
    }
    span:nth-child(2){
      transform: rotate(45deg);
      transition: 0.5s !important;
    }
    span:nth-child(3){
      opacity: 0;
      transition: 0.5s !important;
    }
  }
}

.openMenuMobil{
    .header-mobil-menu{
        right: 0px !important;
        transition: 0.5s !important;
    }
}

@media only screen and (max-width: 992px) {
  .header-items{
    .menu-list{
      display: none !important;
    }
  }

  .header-mobil-menu{
    display: block !important;
  }

  .header-mobil-menu {
      .menu-list{
        flex-direction: column !important;
        gap: 15px;

        a{
          margin-right:0 !important ;
        }
      }

      .d-none{
          display: block !important;
      }
    }
}

@media only screen and (min-width: 992px) {
  .header-mobil-menu-toggle{
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-bottom-link{
    justify-content: center;
  }
}

@media only screen and (max-width: 380px) {
  .header-logo{
    width: 37px;
    height: 40px;
    overflow: hidden;

    img{
      width: 138px;
      object-position: left;
      object-fit: none;
    }
  }

  .trade__intro .trade__amount input{
    width: 130px;
  }
}

.openMenuMobil{
  overflow: hidden;
}

.trade__intro .trade__amount{
  padding: 7px;
  padding-left: 16px;
  position: relative;

  .result:nth-child(1){
    height:40px;
    display: flex;
    align-items: center;
  }

  .result{
    height: 40px;
    display: flex;
    align-items: center;
  }

  input{
    border: 1px solid #050620;
    border-radius: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    padding-left: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #050620;
    width: 120px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.trade__amountWrite{
  input{
    right: 130px !important;
  }
}

@media only screen and (max-width: 767px) {
  .trade__amountWrite{
  input{
    right: 0px !important;
  }

  &::before{
    margin-right: 44px !important;
  }
}

.trade__amountRed{
  &::after{
    left: -50px !important;
  }
}

.trade__amountGreen{
  &::after{
    left: -50px !important;
  }
}
}

.trade__header-text{
  .trade__header-text{
    font-weight: 600;
  }
}

.trade__markets-item{
  padding-left: 7px;
}

.trade__markets-item + .trade__markets-item{
  margin-left: 15px;
}

.vodiapicker__block{
  position: relative;
  background: #fff;

  select{
    -webkit-appearance: none;
    background: none;
  }

  img{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.plans__container .custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color: #BD00FF;
    background-color: #BD00FF;
    box-shadow: none;
    left: 14px;
    top: 14px;
}

.plans__container .custom-control-input~.custom-control-label::before{
    color: #fff;
    border-color: #9f9f9f;
    background-color: #ffffff;
    box-shadow: none;
    left: 14px;
    top: 14px;
}

.plans__header .d-flex{
  padding-left:0 !important ;
}

.plans__container .custom-radio .custom-control-input:checked~.custom-control-label::after{
    left: 14px;
    top: 14px;
}
input.is-invalid,select.is-invalid, .was-validated input:invalid {
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.modal-md{
  max-width: 600px;
}